import React, { useEffect } from "react";
import { useWasm, useLoadedWasm, WASM_READY_STATE } from "./useWasm";
import ClosableWindows from "./components/ClosableWindow";

const TestComponent = () => {
  const { wasm, readyState } = useLoadedWasm();

  useEffect(() => {
    if (readyState === WASM_READY_STATE.READY) {
      async function run() {
        wasm.main_js();
      }

      run();
    }
  }, [readyState, wasm]);

  return (
    <div>
      <div>
        Resources: <span id="resources">0.00</span>
      </div>
      <div>
        Resource Rate:
        <span id="resource-rate">0.00</span>
      </div>
    </div>
  );
};

const App = () => {
  const [WasmProvider, wasmObject] = useWasm();

  return (
    <WasmProvider value={wasmObject}>
      <div id="App">
        {wasmObject.readyState === WASM_READY_STATE.READY && <TestComponent />}

        <div>
          <ClosableWindows />
        </div>
      </div>
    </WasmProvider>
  );
};

export default App;
