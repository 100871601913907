import React, { useState } from "react";
import "./ClosableWindows.css";
import { Ad } from "./Ad";

const getRandomPosition = () => {
  const x = Math.floor(Math.random() * window.innerWidth * 0.8);
  const y = Math.floor(Math.random() * window.innerHeight * 0.8);
  return { x, y };
};

const ClosableWindow = ({ id, title, content, onClose, position }) => (
  <div className="window" style={{ top: position.y, left: position.x }}>
    <div className="header">
      <span>{title}</span>
      <button
        id="increase-rate"
        onClick={() => onClose(id)}
        className="closeButton"
      >
        X
      </button>
    </div>
    <div className="content">
      {content}
      <Ad client="ca-pub-4256810050612637" slot="4131328761" />
    </div>
  </div>
);

const ClosableWindows = () => {
  const [windows, setWindows] = useState([
    {
      id: 1,
      title: "Some ad",
      content: "Hier könnte ihre Werbung stehen",
      position: getRandomPosition(),
    },
  ]);

  const closeWindow = (id) => {
    setWindows(
      windows.map((window) => {
        return window.id === id
          ? { ...window, position: getRandomPosition() }
          : window;
      })
    );
  };

  return (
    <div>
      {windows.map((window) => (
        <ClosableWindow
          key={window.id}
          id={window.id}
          title={window.title}
          content={window.content}
          onClose={closeWindow}
          position={window.position}
        />
      ))}
    </div>
  );
};

export default ClosableWindows;
