import React, { useEffect } from "react";

export const Ad = ({
  client,
  slot,
  format = "auto",
  responsive = "true",
  layoutKey = "",
}) => {
  useEffect(() => {
    (window.adsbygoogle = window.adsbygoogle || []).push({});
  }, []);

  return (
    <ins
      className="adsbygoogle"
      style={{ display: "inline-block", width: "500px", height: "100px" }}
      data-ad-client={client}
      data-ad-slot={slot}
      data-ad-format={format}
      data-full-width-responsive={responsive}
      data-ad-layout-key={layoutKey}
    ></ins>
  );
};
